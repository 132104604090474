import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'surname',
    standalone: true,
})

/**
 * Returns well formatted name & two-part surname 
 */
export class SurnamePipe implements PipeTransform {
    transform(input: string = ''): string {
        return input
            .split(' ')
            .map(m => `${m.substring(0, 1).toLocaleUpperCase()}${m.substring(1).toLocaleLowerCase()}`)
            .join(' ')
            .split('-')
            .map(m => `${m.substring(0, 1).toLocaleUpperCase()}${m.substring(1)}`)
            .join('-');
    }
}